<template>
	<div>
		<div v-if="error">
			{{ error }}
		</div>
		<main v-if="homePage.attributes">
			<div class="homepage_header front_page">
				<div class="home-hero" id="hero-section">
					<home-hero-left
						:locationInfo="locationInfo"
						:titleFirstLine="homePage.attributes.titleFirstLine"
						:titleSecondLine="homePage.attributes.titleSecondLine"
						:addressInputTitle="
							homePage.attributes.addressInputTitle
						"
					/>
					<home-hero-right
						:market="market"
						:rightHeroImages="marketHeroImages"
					/>
				</div>
			</div>
			<market-links />
			<app-section
				:homePageLinks="homePageLinks"
				:appSectionTitle="homePage.attributes.appSectionTitle"
				:appSectionCopy="homePage.attributes.appSectionCopy"
				:appImageURL="homePage.attributes.appImageURL"
			/>
			<feature-section
				:featureSection="homePage.attributes.featureSection"
			/>
			<!-- <section class='buy-lunch section section--blue section--padding'>
					<div class='container'>
						<h2 class='buy-lunch__title'>Keep your employees close and their lunch closer.</h2>
						<p class='buy-lunch__copy'>We make it easy to feed your office with fast and convenient online ordering for all employees, with just one invoice.</p>
						<a class='button' href='/corporate'>Get Started</a>
					</div>
				</section> 
			-->
			<owner-section :restOwners="homePage.attributes.restOwners" />
			<delivery-hero-section
				:deliveryHeroTitle="homePage.attributes.deliveryHeroTitle"
				:deliveryHeroCopy="homePage.attributes.deliveryHeroCopy"
				:deliveryHeroLink="homePage.attributes.deliveryHeroLink"
				:deliveryHeroImageURL="homePage.attributes.deliveryHeroImageURL"
				:deliveryHeroImageAlt="homePage.attributes.deliveryHeroImageAlt"
				:deliveryHeroBtnText="homePage.attributes.deliveryHeroBtnText"
			/>
		</main>
		<out-foot
			:homePageLinks="homePageLinks"
			:businessSocial="businessSocial"
			:businessInfo="businessInfo"
			:footerLogoURL="footerLogoURL"
		/>
	</div>
</template>
<script>
import OutFoot from "../page-components/OutFoot.vue";
import DeliveryHeroSection from "./DeliveryHeroSection.vue";
import OwnerSection from "./OwnerSection.vue";
import FeatureSection from "./FeatureSection.vue";
import AppSection from "./AppSection.vue";
import MarketLinks from "./MarketLinks.vue";
import HomeHeroLeft from "./HomeHeroLeft.vue";
import HomeHeroRight from "./HomeHeroRight.vue";
import { mapState, mapActions } from "vuex";

import axios from "axios";

export default {
	components: {
		OutFoot,
		DeliveryHeroSection,
		OwnerSection,
		FeatureSection,
		AppSection,
		MarketLinks,
		HomeHeroLeft,
		HomeHeroRight,
	},
	created() {
		this.setInitHomePageState();
	},
	async mounted() {
		try {
			const response = await axios.get("/strapi/api/home?[populate]=*");
			this.homePage = response.data.data;
		} catch (error) {
			this.error = error;
		}
		const footerLogoResponse = await axios.get(
			"/strapi/api/header-footer-logo?[populate]=*"
		);
		this.footerLogoURL =
			footerLogoResponse.data.data.attributes.footerLogoURL;
		var position = await this.getPosition();
		var params = new URLSearchParams();
		params.append("mode", "whichzone");
		params.append("lat", position.lat);
		params.append("lng", position.lng);

		var selectZoneResponse = await axios
			.post("/selectZone.pl?", params)
			.then((response) => {
				if (response.data.errors) {
					return 0;
				} else if (response.data.success) {
					if (response.data.success.blocked) {
						return 0;
					}
					return response.data.success;
				}
			})
			.catch(function (error) {
				return 0;
			});

		this.zoneId = selectZoneResponse.zoneID;
		this.market = selectZoneResponse.market;
	},
	data: function () {
		return {
			homePage: {},
			zoneId: 0,
			market: "general",
			error: null,
			footerLogoURL: "",
		};
	},
	computed: {
		...mapState({
			locationInfo: (state) => state.homePage.homePageData.locationInfo,
			homePageLinks: (state) => state.homePage.homePageData.homePageLinks,
			businessSocial: (state) =>
				state.homePage.homePageData.businessSocial,
			businessInfo: (state) => state.homePage.homePageData.businessInfo,
		}),
		marketHeroImages: function () {
			var marketHeroImagesReturn =
				this.homePage.attributes.rightHeroImages.filter(
					(image) => image.tag == this.market
				);
			if (marketHeroImagesReturn.length > 0) {
				return marketHeroImagesReturn;
			}
			return this.homePage.attributes.rightHeroImages.filter(
				(image) => image.tag == "general"
			);
		},
	},
	methods: {
		...mapActions({
			setInitHomePageState: "homePage/setInitHomePageState",
		}),
		getPosition() {
			return new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						resolve({
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						});
					},
					(error) => {
						console.log(error.message);
					}
				);
			});
		},
	},
};
</script>
<style>
#home-page {
	color: #333;
}
#modal-background {
	background-color: #000;
	opacity: 0.5;
	position: fixed;
	top: 0;
	height: 100%;
	width: 100%;
}
.map__title {
	color: white;
}
.not-scrollable {
	overflow: hidden;
}
.modal {
	overflow-x: hidden;
	overflow-y: auto;
}
.modal-content {
	height: 100%;
}
.modal-loading {
	display: block;
	text-align: right;
	margin-top: 5px;
	margin-right: 2px;
}
.exit-popup {
	position: absolute;
	top: 10px;
	right: 15px;
	z-index: 5;
	cursor: pointer;
	border: none;
	background-color: inherit;
}
.exit-popup i {
	font-size: 2.9rem;
	color: #cacaca;
}
</style>