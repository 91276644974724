<template>
	<div class="home-hero__side">
		<div class="restaurant-slider">
			<VueSlickCarousel v-bind="settings" ref="carousel">
				<div
					v-for="(image, i) in rightHeroImages"
					:key="i"
					class="restaurant-slider__slide"
				>
					<img
						class="restaurant-slider__image"
						:src="image.imageURL"
						:alt="image.imageAlt"
					/>
					<div class="restaurant-slider__caption">
						{{ image.title }}
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
	components: {
		VueSlickCarousel
	},
	mounted() {
		this.$nextTick(function() {
			var slider = document.querySelectorAll(".slick-slide > div");
			for (var i = 0; i < slider.length; i++) {
				slider[i].style.height = "100%";
			}
		});
	},
	props: {
		rightHeroImages: Array
	},
	data: function() {
		return {
			settings: {
				arrows: false,
				dots: false,
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				// speed: 5000,
				// autoplaySpeed: 10000,
				fade: true
			}
		};
	}
};
</script>
<style scoped>
.slick-slider {
	height: 100%;
}
.slick-track {
	height: 100%;
}
.restaurant-slider__image {
	height: 100%;
}
.restaurant-slider .restaurant-slider__slide {
	height: 100%;
}
</style>