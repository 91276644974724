<template>
	<footer class="footer">
		<div class="footer__top">
			<div class="container">
				<div class="row">
					<div class="col-sm-4 col-lg-2">
						<div class="footer__nav-header">Discover</div>
						<a class="footer__nav-link" href="/about">Our Story</a>
						<a class="footer__nav-link" href="/contactus">Contact Us</a>
						<a class="footer__nav-link" href="/giftcards">Gift Cards</a>
						<a class="footer__nav-link" href="/press">Press</a>
						<a class="footer__nav-link" href="/blog">Blog</a>
						<!--<a class='footer__nav-link' href='#'>Cities</a>-->
					</div>
					<div class="col-sm-4 col-lg-2">
						<div class="footer__nav-header">Help</div>
						<a class="footer__nav-link" href="/faq">FAQ</a>
						<a class="footer__nav-link" href="/privacy">Privacy Policy</a>
						<a class="footer__nav-link" href="/terms">Terms of Service</a>
					</div>
					<div class="col-sm-4 col-lg-2">
						<div class="footer__nav-header">Catering</div>
						<a class="footer__nav-link" href="/corporate">House Accounts</a>
						<!--<a class='footer__nav-link' href='/groupOrder.pl'>Group Ordering</a>
							<a class='footer__nav-link' href='#'>Catering</a>-->
					</div>
					<div class="col-sm-4 col-lg-2">
						<div class="footer__nav-header">Drivers</div>
						<a class="footer__nav-link" href="/drivers">Sign Up</a>
						<a class="footer__nav-link" href="/driver-dashboard">Login</a>
					</div>
					<div class="col-sm-4 col-lg-2">
						<div class="footer__nav-header">Restaurants</div>
						<a class="footer__nav-link" href="/partners">Sign Up</a>
						<a class="footer__nav-link" href="/dashboard">Login</a>
					</div>
					<div class="col-sm-4 col-lg-2">
						<div class="footer__nav-header">Social</div>
						<a class="footer__nav-link" :href="`https://www.facebook.com/${businessSocial.facebook}`" target="_blank">
							<img width="28px" height="28px" :src="$CDN + '/images/facebook-white-icon.png'" :alt="'Facebook'" />
						</a>
						<a class="footer__nav-link" :href="`https://www.instagram.com/${businessSocial.instagram}`" target="_blank">
							<img width="28px" height="30px" :src="$CDN + '/images/instagram-white-icon.png'" :alt="'Instagram'" />
						</a>
					</div>
				</div>
				<!-- end .row -->
			</div>
			<!-- end .container -->
		</div>
		<!-- end .footer -->
		<div class="footer__bottom">
			<img width="270" height="78.7167" class="footer__logo" :src="footerLogoURL" :alt="businessInfo.businessName + ' logo'" />
		</div>
	</footer>
</template>

<script>
export default {
	props: {
		homePageLinks: {
			type: Object,
		},
		businessSocial: {
			type: Object,
		},
		businessInfo: {
			type: Object,
		},
		footerLogoURL: {
			type: String,
		},
	},
};
</script>
