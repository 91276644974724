<template>
	<div v-if="logos.attributes" class="logo_container">
		<a class="desktop_logo" :href="logos.attributes.homePageURL"
			><img
				width="400"
				height="85.99"
				class="img-responsive menu_logo"
				:src="logos.attributes.headerLogoURL"
				:alt="logos.attributes.logoAltText"
				:title="logos.attributes.logoTitle"
		/></a>
		<a class="mobile_logo" :href="logos.attributes.homePageURL"
			><img
				width="200"
				height="40.61"
				class="img-responsive menu_logo"
				:src="logos.attributes.headerLogoURL"
				:alt="logos.attributes.logoAltText"
				:title="logos.attributes.logoTitle"
		/></a>
	</div>
</template>

<script>
import axios from "axios";

export default {
	data: function () {
		return {
			logos: {},
			error: null,
		};
	},
	async mounted() {
		try {
			const response = await axios.get(
				"/strapi/api/header-footer-logo?[populate]=*"
			);
			this.logos = response.data.data;
		} catch (error) {
			this.error = error;
		}
	},
};
</script>